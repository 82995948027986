import React from "react"
import { graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-react-intl"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeaderColor from "../components/HeaderColor"
import HeaderCover from "../components/HeaderCover"
import Footer from "../components/Footer"
import { Row, Col, Image } from "antd"
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
} from "react-share"
import styled from "@emotion/styled"

const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      if (node.data && node.data.target) {
        const url = node.data.target.file.url
        return (
          <div className="richImgWrapper">
            <Image
              src={url}
              alt=""
            />
          </div> 
        )
      } else {
        return null
      }
    },
  },
}

const PageWrapper = styled.div`
  margin: 80px 0 80px 0;
  .headerWrapper {
    font-family: "Kanit", sans-serif;
    .title {
      font-weight: 400;
      font-size: 32px;
      color: #181818;
      margin-bottom: 20px;
    }
    .cat {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 140px;
      max-width: calc(100% - 30px);
      min-height: 42px;
      padding: 10px 15px;
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 16px;
      color: #fff;
      background: #7497b2;
      border-radius: 5px;
    }
    .date {
      font-weight: 300;
      font-size: 16px;
      color: #646464;
      margin-bottom: 0;
      padding-left: 15px;
    }
  }
  .bodyRichText {
    position: relative;
    margin-top: 25px;
    padding-top: 30px;
    border-top: 1px solid #dad5cf;
    hr {
      margin: 35px 0;
      color: #dad5cf;
      border-top: 0 solid #dad5cf;
    }
    ol,
    ul,
    blockquote,
    p {
      font-family: "Kanit", sans-serif;
      font-weight: 300;
      font-size: 16px;
      color: #646464;
      margin-left: 0;
    }
    ol,
    ul {
      li {
        p {
          font-weight: 400;
          margin-left: 0 !important;
        }
      }
    }
    blockquote {
      background: #7497b2;
      padding: 30px;
      p {
        font-weight: 400;
        color: #fff;
        margin-left: 0 !important;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .richImgWrapper {
      margin: 35px auto;
      text-align: right;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .shareWrapper {
    position: relative;
    top: auto;
    left: auto;
    margin-bottom: 30px;
    p {
      font-family: "Kanit", sans-serif;
      font-weight: 400;
      font-size: 18px;
      color: #181818;
      margin-bottom: 15px;
      margin-left: 0 !important;
    }
    ul {
      list-style: none;
      margin: 0 !important;
      padding: 0;
      display: flex;
      align-items: center;
      li {
        margin-right: 10px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  .relatedArticles {
    margin-top: 15px;
    .header {
      font-family: "Kanit", sans-serif;
      font-weight: 400;
      font-size: 18px;
      color: #181818;
      margin-bottom: 25px;
    }
  }

  @media only screen and (min-width: 576px) {
    .headerWrapper {
      .title {
        font-size: 34px;
      }
    }
    .bodyRichText {
      ol,
      ul,
      blockquote,
      p {
        margin-left: 150px;
      }
    }
    .shareWrapper {
      position: absolute;
      top: 30px;
      left: 0;
    }
  }
  @media only screen and (min-width: 768px) {
    margin: 100px 0 80px 0;
    .headerWrapper {
      .title {
        font-size: 36px;
      }
    }
    .bodyRichText {
      ol,
      ul,
      blockquote,
      p {
        margin-left: 180px;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .headerWrapper {
      .title {
        font-size: 38px;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .bodyRichText {
      ol,
      ul,
      blockquote,
      p {
        margin-left: 220px;
      }
    }
  }
`
const RelatedCard = styled.div`
  background: #fff;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  height: 100%;
  min-height: 100%;
  &:hover {
    box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.2);
  }
  .featuredWrapper {
    display: flex;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    a {
      display: inline-flex;
    }
    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
  .contentWrapper {
    padding: 25px 20px;
    font-family: "Kanit", sans-serif;
    .date {
      font-weight: 300;
      font-size: 18px;
      color: #646464;
      margin-bottom: 0;
    }
    h3 {
      font-weight: 400;
      font-size: 18px;
      color: #181818;
      margin-bottom: 0;
    }
  }
`

const ProjectsSinglePage = ({ data, intl }) => {
  const locale = intl && intl.locale ? intl.locale : `th`
  const { singleProjects, relatedProjects, site } = data
  const {
    title,
    slug,
    seoTitle,
    seoDescription,
    featured,
    createdAt,
    body,
  } = singleProjects.edges[0].node
  const getFeatured = getImage(featured)
  const { siteUrl } = site.siteMetadata
  const shareUrl = siteUrl + `/projects/` + slug + `/`

  return (
    <Layout>
      <Seo
        lang={locale}
        title={seoTitle}
        description={seoDescription}
        banner={featured ? featured.file.url : ``}
        article
        pathname={shareUrl}
      />
      <HeaderColor />
      <HeaderCover
        bgImage={getFeatured}
        coverText={title}
        secondLink="/projects/"
        secondText={intl.formatMessage({ id: "rootMenu3Sub2" })}
      />
      <PageWrapper>
        <div className="gb-container">
          <Row>
            <Col xs={24} sm={20} md={18} lg={14} xl={12}>
              <div className="headerWrapper">
                <h2
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
                <p className="cat">
                  {intl.formatMessage({ id: "rootMenu3Sub2" })}
                </p>
                <p className="date">{createdAt}</p>
              </div>
            </Col>
          </Row>
          <Row gutter={[80, 40]}>
            <Col xs={24} xl={16}>
              <div className="bodyRichText">
                <div className="shareWrapper">
                  <p>{intl.formatMessage({ id: "textShare" })}</p>
                  <ul>
                    <li>
                      <FacebookShareButton url={shareUrl}>
                        <FacebookIcon size={24} round={true} />
                      </FacebookShareButton>
                    </li>
                    <li>
                      <LineShareButton url={shareUrl}>
                        <LineIcon size={24} round={true} />
                      </LineShareButton>
                    </li>
                    <li>
                      <TwitterShareButton url={shareUrl}>
                        <TwitterIcon size={24} round={true} />
                      </TwitterShareButton>
                    </li>
                  </ul>
                </div>
                {renderRichText(body, richTextOptions)}
              </div>
            </Col>
            <Col xs={24} xl={8}>
              <div className="relatedArticles">
                <p className="header">
                  {intl.formatMessage({ id: "rootMenu3Sub2" })}
                </p>
                <Row gutter={[16, 32]}>
                  {relatedProjects &&
                    relatedProjects.edges.map(related => {
                      const relatedFeatured = getImage(related.node.featured)

                      return (
                        <Col key={related.node.slug} xs={24} sm={12} xl={24}>
                          <RelatedCard>
                            <div className="featuredWrapper">
                              <Link to={`/projects/${related.node.slug}/`}>
                                <GatsbyImage image={relatedFeatured} alt="" />
                              </Link>
                            </div>
                            <div className="contentWrapper">
                              <p className="date">{related.node.createdAt}</p>
                              <Link to={`/projects/${related.node.slug}/`}>
                                <h3
                                  dangerouslySetInnerHTML={{
                                    __html: related.node.title,
                                  }}
                                />
                              </Link>
                            </div>
                          </RelatedCard>
                        </Col>
                      )
                    })}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </PageWrapper>
      <Footer />
    </Layout>
  )
}

export default injectIntl(ProjectsSinglePage)

export const query = graphql`
  query ProjectsSingleQuery(
    $slug: String!
    $locale: String
    $language: String
  ) {
    singleProjects: allContentfulProjectsPosts(
      filter: { slug: { eq: $slug }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          title
          slug
          seoTitle
          seoDescription
          createdAt(formatString: "DD MMM YYYY", locale: $language)
          featured {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            file {
              url
            }
          }
          body {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
    relatedProjects: allContentfulProjectsPosts(
      filter: { slug: { ne: $slug }, node_locale: { eq: $locale } }
      sort: { 
        order: DESC, 
        fields: createdAt
      }
      limit: 2
    ) {
      edges {
        node {
          title
          slug
          createdAt(formatString: "DD MMM YYYY", locale: $language)
          featured {
            gatsbyImageData(width: 510, height: 401, placeholder: BLURRED)
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
